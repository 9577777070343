import { Form, Formik, FormikHelpers } from 'formik';
import React, { PureComponent } from 'react';
import styles from 'libs/common/styles/pages/auth/LoginPage.module.scss';
import { apiPost, CONTENT_TYPES, LOGIN_API } from 'libs/common/src/utils/ApiUtils';
import { OrsysContext, OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import Input from '../../src/components/widgets/Input/Input';
import Button from '../../src/components/widgets/Button/Button';
import { GetServerSideProps } from 'next';
import { injectIntl } from 'react-intl';
import { getTranslations, WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import OrsysTextLogo from '../../src/components/svg/OrsysTextLogo';
import Card from '../../src/components/widgets/Card/Card';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../src/components/widgets/Modal/Modal';
import { errorToast, successToast } from 'libs/common/src/utils/ToastUtils';
import { EMAIL_PATTERN, SUCCESSFULL_TOAST_AUTOCLOSE, USERNAME_PATTERN } from 'libs/common/src/utils/GlobalConstant';
import * as yup from 'yup';
import { UserDetailedReadDTO } from '@orsys/common';

interface LoginPageProps extends WithRouterProps, WithIntlComponentProps {}

interface LoginPageState {
	modalOpen: boolean;
}

interface LoginFormState {
	username?: string;
	password?: string;
	stayLoggedIn?: boolean;
	errors?: string[];
}

interface ResetPasswordFormState {
	email?: string;
	errors?: string[];
}

class LoginPage extends PureComponent<LoginPageProps, LoginPageState> {
	static contextType = OrsysContext;

	state: LoginPageState = {
		modalOpen: false,
	};

	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen,
		});
	};

	login = async (values: LoginFormState, { setSubmitting }: FormikHelpers<LoginFormState>) => {
		const { intl } = this.props;
		let formData = new FormData();
		formData.append('username', values.username as string);
		formData.append('password', values.password as string);
		try {
			const { data, headers } = await apiPost<UserDetailedReadDTO>(LOGIN_API, formData);
			// @ts-ignore
			const { setUser, setStayLoggedIn }: OrsysContextProviderValues = this.context;
			if (data) {
				setUser(data, headers);
				setStayLoggedIn(values.stayLoggedIn === true);
			}
		} catch (e) {
			errorToast(intl.formatMessage({ id: 'toast.login_failed' }), {
				autoClose: 5000,
			});
			console.error('ERR', e);
		}
		setSubmitting(false);
	};

	render() {
		const { intl } = this.props;
		const { modalOpen } = this.state;
		const initLoginState: LoginFormState = {
			username: '',
			password: '',
			stayLoggedIn: false,
			errors: [],
		};
		const initResetPasswordState: ResetPasswordFormState = {
			email: '',
			errors: [],
		};

		const loginValidationSchema = yup.object().shape({
			username: yup
				.string()
				.required(intl.formatMessage({ id: 'error.required_field' }))
				.matches(USERNAME_PATTERN, intl.formatMessage({ id: 'error.invalid_username' })),
		});

		const emailResetValidationSchema = yup.object().shape({
			email: yup
				.string()
				.required(intl.formatMessage({ id: 'error.required_field' }))
				.matches(EMAIL_PATTERN, intl.formatMessage({ id: 'error.invalid_email_address' })),
		});

		return (
			<div className={styles.container}>
				<div className={styles.logoMobile}>
					<OrsysTextLogo />
				</div>
				<Card
					className={styles.formContainer}
					title={intl.formatMessage({ id: 'auth.login' })}
					borderBottom>
					<div className={styles.logo}>
						<OrsysTextLogo />
					</div>
					<Formik<LoginFormState>
						initialValues={initLoginState}
						validateOnBlur={true}
						validationSchema={loginValidationSchema}
						onSubmit={(values, actions) => {
							this.login(values, actions);
						}}>
						{({ isSubmitting, isValid, errors, touched }) => (
							<Form>
								<Input
									autoComplete={'off'}
									vertical
									validate
									type='username'
									name='username'
									error={errors.username}
									touched={touched.username}
									inputLabel={intl.formatMessage({ id: 'auth.username' })}
								/>
								<Input
									autoComplete={'off'}
									type='password'
									name='password'
									vertical
									validate
									error={errors.password}
									touched={touched.password}
									inputLabel={intl.formatMessage({ id: 'auth.password' })}
								/>
								<a
									className={styles.forgotLink}
									onClick={this.toggleModal}>
									{intl.formatMessage({ id: 'auth.forgot_password' })}
								</a>
								<Input
									type='checkbox'
									name='stayLoggedIn'
									className={styles.stayLoggedIn}
									inputLabel={intl.formatMessage({ id: 'auth.stay_logged_in' })}
								/>
								<Button
									formType='submit'
									className={styles.submitButton}
									disabled={isSubmitting || !isValid}>
									{intl.formatMessage({ id: 'auth.login' })}
									{isSubmitting && (
										<FontAwesomeIcon
											icon={faSpinner}
											spin
										/>
									)}
								</Button>
							</Form>
						)}
					</Formik>
				</Card>
				<Modal
					title={intl.formatMessage({ id: 'auth.request_new_password' })}
					onClose={this.toggleModal}
					closeButton
					open={modalOpen}
					borderBottom>
					<Formik
						initialValues={initResetPasswordState}
						validateOnBlur={true}
						validationSchema={emailResetValidationSchema}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const body = {
									email: values.email,
								};
								await apiPost<void>('/auth/password', JSON.stringify(body), CONTENT_TYPES.JSON);
								successToast(intl.formatMessage({ id: 'toast.modification_successful' }), {
									autoClose: SUCCESSFULL_TOAST_AUTOCLOSE,
								});
							} catch (e) {
								errorToast(`${e}`, {
									autoClose: 5000,
								});
							}
							setSubmitting(false);
						}}>
						{({ isSubmitting, isValid, errors, touched }) => (
							<Form>
								<Input
									vertical
									validate
									type='email'
									name='email'
									error={errors.email}
									touched={touched.email}
									inputLabel={intl.formatMessage({ id: 'auth.email_address' })}
								/>
								<Button
									formType='submit'
									className={styles.submitButton}
									disabled={isSubmitting || !isValid}>
									{intl.formatMessage({ id: 'send' })}
									{isSubmitting && (
										<FontAwesomeIcon
											icon={faSpinner}
											spin
										/>
									)}
								</Button>
							</Form>
						)}
					</Formik>
				</Modal>
			</div>
		);
	}
}

export const getServerSideProps: GetServerSideProps = async (context) => {
	return {
		props: {
			locale: context.locale,
		},
	};
};

export default withRouter<LoginPageProps>(injectIntl(LoginPage));
