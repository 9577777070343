import React, { CSSProperties, FunctionComponent, PropsWithChildren } from 'react';
import styles from './Card.module.scss';

interface CardProps extends PropsWithChildren {
	title?: string | React.ReactNode;
	borderBottom?: boolean;
	rightHeader?: any;
	className?: string;
	style?: CSSProperties;
}

const Card: FunctionComponent<CardProps> = (props) => {
	const { title, children, borderBottom, rightHeader, className, style } = props;
	return (
		<div
			className={`${styles.card} ${className ? className : ''}`}
			style={style}>
			{title && (
				<div
					className={`${styles.titleContainer} ${borderBottom ? styles.withBorderBottom : ''} ${
						rightHeader ? styles.rightHeader : ''
					}`}>
					<div className={styles.title}>{title && title}</div>
					{rightHeader && <div className={styles.right}>{rightHeader}</div>}
				</div>
			)}
			{children}
		</div>
	);
};

export default Card;
